/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, SideBySide, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you start to lose your hearing, the impact goes well beyond missing a few words in conversations and having to ask people to repeat what they say. That’s a major irritation, to be sure, but it’s really the tip of the ", React.createElement(_components.a, {
    href: "/hearing-loss/",
    className: "c-md-a"
  }, "hearing loss"), " iceberg."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are actually many ways in which even slight levels of hearing loss can adversely affect our quality of life, but which can be alleviated by ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "finding the right hearing aid"), "."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Let’s have a look at some situations where hearing loss can cause serious, and often unexpected, problems."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-impact-of-hearing-loss-at-a-restaurant",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-impact-of-hearing-loss-at-a-restaurant",
    "aria-label": "the impact of hearing loss at a restaurant permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The impact of hearing loss at a restaurant"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-chatting-in-restaurant.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Restaurants are places with a lot of ambient noise (at least when they are busy, and all of the best places tend to attract a lot of patrons). Normally, this background chatter is what gives eateries a bit of character, and it’s no great problem. However, when you can’t hear properly, ambient noise can make it very difficult to converse with your fellow diners or hear the waiting staff. All-too-often, people with hearing loss will inquire about the house specials, only to nod their heads as if they understood everything, before returning to the printed menu to settle for something they might not want. And it’s a problem that may be getting worse.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "One UK-based charity, Action On Hearing Loss, reports that 81 percent of those surveyed had experienced difficulties sustaining conversations in places to eat. Most restaurants aren’t designed with acoustics in mind, which only makes the problem worse, although some have thought about the issue, adding things like cork wood ceilings to absorb sound waves – so help is at hand."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-loss-in-the-work-environment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-loss-in-the-work-environment",
    "aria-label": "hearing loss in the work environment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing loss in the work environment"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-loss-in-the-work-environment.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "The same issues arise at work as in restaurants. For example, it can become progressively harder to follow discussions and presentations in meeting rooms when hearing loss develops. And if your work relies on communicating with clients, poor comprehension can often come across as rudeness. In some workplaces, hearing loss can be extremely hazardous. Take construction sites, for example, where verbal instructions can be the difference between life and death. Studies have backed this up, finding that in noisy work environments, employees with hearing loss are 3.6 times more likely to be hospitalized by an injury sustained at work. Other implications of undiagnosed hearing loss in the workplace can be less dangerous, but still very disheartening.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Not only can your performance deteriorate relative to the past and co-workers, but dismissal becomes more likely too. Stress is another inevitable consequence of allowing hearing loss problems to go untreated in work settings, and this can lead to all kinds of secondary health issues. Hearing loss is an issue ", React.createElement(_components.a, {
    href: "/resources/hearing-loss/10-jobs",
    className: "c-md-a"
  }, "in almost every working environment in the USA"), ". From kitchens to classrooms, when our hearing starts to fade, our jobs become harder, so take action if it happens to you, and sooner rather than later. Employers and co-workers will understand – but will only be confused if you continue to battle against your auditory problems."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-hearing-loss-affects-life-in-cities",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-hearing-loss-affects-life-in-cities",
    "aria-label": "how hearing loss affects life in cities permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How hearing loss affects life in cities"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/how-hearing-loss-affect-life-in-cities.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "The problems associated with hearing loss extend well beyond the office. When you leave to catch the bus or subway home, you rely on your hearing in a multitude of ways. For instance, on the subway platform, when announcers cancel trains or let you know about delays, it’s hard enough to comprehend them over poor-quality PA systems without hearing loss. But when your ears can’t pick up different words, and focusing on speech becomes hard, staying informed about transportation becomes a nightmare.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In unfamiliar cities, you’ll start to miss announcements about stops, or you’ll miss information given by station attendants. In the process, you’ll quickly come to understand how important speech is to getting around modern cities, even with all the apps in the world to guide us. Then there are our fellow commuters. When we travel to and from work, we have to ask people to move aside, apologize, smile, say hello – all of the little things that make massive movements of people run smoothly. If you can’t hear well, it becomes more confusing and stressful to deal with strangers, particularly on packed buses or trains. And finally there’s the street. When you can’t hear properly, it can be difficult to pick up the noise of approaching traffic, and pushbikes are really hard to detect, raising the risk of accidents."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "ways-that-hearing-loss-can-affect-our-free-time",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ways-that-hearing-loss-can-affect-our-free-time",
    "aria-label": "ways that hearing loss can affect our free time permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ways that hearing loss can affect our free time"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/ways-that-hearing-loss-can-affect-our-free-time.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Even the way we relax can be adversely affected by hearing loss, causing depression and frustration if the issues aren’t dealt with. If you ", React.createElement(_components.a, {
    href: "/resources/hearing-aids/ear-protection-hunting",
    className: "c-md-a"
  }, "hunt"), ", you’ll appreciate the value of keen hearing when detecting prey and remaining undetected. Musicians obviously know how sensitive ears help their ability to determine pitch. And in sports, communication between players is vital. Imagine being in the offensive line and having no idea what your Quarterback is saying. But hearing loss problems apply everywhere. In the cinema, it can seem like you’re sliding gradually back into the Silent Age. Socializing with friends in-person or by phone can become more difficult, and TV viewing can become infuriating as shows that were once absorbing now become a mess of noises that won’t come together.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "have-your-hearing-checked-out-to-prevent-hearing-loss-frustration",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#have-your-hearing-checked-out-to-prevent-hearing-loss-frustration",
    "aria-label": "have your hearing checked out to prevent hearing loss frustration permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Have your hearing checked out to prevent hearing loss frustration"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/womenshoot-ido-pinch.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "As you can see, hearing loss can have huge negative impacts on everyday life. From meal times to the office, from construction sites to cinemas, when your hearing starts to fade, everything becomes slightly harder, less enjoyable, and more confusing. But you can stop this process by seeking the right assistance. So, if you detect the signs of encroaching hearing loss, arrange a consultation with an expert, who can recommend the right treatment and hearing aid before the problems become severe.")), "\n", React.createElement(LandingPageCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
